import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Message, MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class ToastService
{
    constructor(
        private messageService: MessageService,
    )
    {
    }

    success(message = 'All good', title = 'Done!', duration = 4000)
    {
        const options: Message = {
            severity: 'success',
            summary: title,
            detail: message,
            closable: true,
            life: duration,
        };
        this.messageService.add(options);
    }

    danger(message = 'Some Error.', title = 'Oh snap!', duration = 4000)
    {
        const options: Message = {
            severity: 'error',
            summary: title,
            detail: message,
            closable: true,
            life: duration,
        };
        this.messageService.add(options);
    }

    showErrorFromResponse(response: HttpErrorResponse)
    {
        let message;

        if (response instanceof HttpErrorResponse)
        {
            if (response.error && response.error.hasOwnProperty('errors'))
            {
                const errors = response.error.errors;

                for (const key in errors)
                {
                    if (errors.hasOwnProperty(key))
                    {
                        message = errors[key];

                        break;
                    }
                }
            }
        }

        this.danger(message);
    }
}
