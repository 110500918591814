import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CreateCallCycleModel, UpdateRepStoreModel } from '@models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CallService
{
    private url = '/calls';

    constructor(
        private http: HttpClient,
    )
    {
    }

    create(cycle: CreateCallCycleModel): Promise<void>
    {
        return this.http
            .post<void>(this.url, cycle)
            .toPromise();
    }

    update(cycle: UpdateRepStoreModel): Promise<void>
    {
        return this.http
            .put<void>(this.url, cycle)
            .toPromise();
    }

    getCallCycle(start: number, rows: number, sortField: string, sortOrder: number, keyword: string, startDate: string, endDate: string): Observable<any>
    {
        return this.http
            .get<any>(`${this.url}?first=${start}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`);
    }

    delete(cycleId: number): Promise<void>
    {
        const url = `${this.url}/${cycleId}`;

        return this.http
            .delete<void>(url)
            .toPromise();
    }
}
