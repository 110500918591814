import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PhotoTypeModel } from '../models/photo/photo-type.model';
import { PhotoReportFilterModel } from '../models/photo/photo-report-filter.model';
import { UpdatePhotoModel } from '../models/photo/update-photo.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PhotoService
{
    private url = '/photos';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getTypes(): Promise<PhotoTypeModel[]>
    {
        return this.http
            .get<PhotoTypeModel[]>(`${this.url}/getTypes`)
            .toPromise();
    }

    get(first, rows, filter): Promise<any>
    {
        return this.http
            .get<any>(`${this.url}?first=${first}&rows=${rows}&type_id=` +
                `${filter.type_id}&date_start=` +
                `${moment(filter.range_dates[0]).format('YYYY-MM-DD')}&date_end=` +
                `${moment(filter.range_dates[1]).format('YYYY-MM-DD')}&store_ids=` +
                `${filter.store_ids.join(',')}&tag_ids=${filter.tag_ids.join(',')}&rep_ids=` +
                `${filter.rep_ids.join(',')}`)
            .toPromise();
    }

    getDownloadLink(filter: PhotoReportFilterModel): Promise<string>
    {
        return this.http
            .post<string>(`${this.url}/getDownloadLink`, {
                type_id: filter.type_id,
                tag_ids: filter.tag_ids,
                range_dates: [moment(filter.range_dates[0]).format('YYYY-MM-DD'), moment(filter.range_dates[1]).format('YYYY-MM-DD')],
                store_ids: filter.store_ids,
                rep_ids: filter.rep_ids,
                external_id: filter.external_id,
            })
            .toPromise();
    }

    getDownloadPPTX(filter: PhotoReportFilterModel): Promise<string>
    {
        return this.http
            .post<string>(`${this.url}/exportToPPTX`, {
                type_id: filter.type_id,
                tag_ids: filter.tag_ids,
                range_dates: [moment(filter.range_dates[0]).format('YYYY-MM-DD'), moment(filter.range_dates[1]).format('YYYY-MM-DD')],
                store_ids: filter.store_ids,
                rep_ids: filter.rep_ids,
                external_id: filter.external_id,
            })
            .toPromise();
    }
    getDownloadXLSX(filter: PhotoReportFilterModel): Promise<string>
    {
        return this.http
            .post<string>(`${this.url}/exportToXLSX`, {
                type_id: filter.type_id,
                tag_ids: filter.tag_ids,
                range_dates: [moment(filter.range_dates[0]).format('YYYY-MM-DD'), moment(filter.range_dates[1]).format('YYYY-MM-DD')],
                store_ids: filter.store_ids,
                rep_ids: filter.rep_ids,
                external_id: filter.external_id,
            })
            .toPromise();
    }

    getReport(first: number, rows: number, sortField: string, sortOrder: number, filter: PhotoReportFilterModel): Observable<any>
    {
        return this.http
            .get<any>(`${this.url}/getReport?first=${first}&rows=${rows}&sortField=` +
                `${sortField}&sortOrder=${sortOrder}&type_id=` +
                `${filter.type_id}&external_id=${filter.external_id}&date_start=` +
                `${moment(filter.range_dates[0]).format('YYYY-MM-DD')}&date_end=` +
                `${moment(filter.range_dates[1]).format('YYYY-MM-DD')}&store_ids=` +
                `${filter.store_ids.join(',')}&tag_ids=${filter.tag_ids.join(',')}&rep_ids=` +
                `${filter.rep_ids.join(',')}&channel_ids=${filter.channel_ids.join(',')}`);
    }

    getReportShare(first: number, rows: number, sortField: string, sortOrder: number, filter: PhotoReportFilterModel, companyId: number): Observable<any>
    {
        return this.http
            .get<any>(`${this.url}/getReportShare?first=${first}&rows=${rows}&sortField=` +
                `${sortField}&sortOrder=${sortOrder}&type_id=` +
                `${filter.type_id}&external_id=${filter.external_id}&date_start=` +
                `${moment(filter.range_dates[0]).format('YYYY-MM-DD')}&date_end=` +
                `${moment(filter.range_dates[1]).format('YYYY-MM-DD')}&store_ids=` +
                `${filter.store_ids.join(',')}&tag_ids=${filter.tag_ids.join(',')}&rep_ids=` +
                `${filter.rep_ids.join(',')}&company_id=${companyId}`);
    }

    delete(photoId: number): Promise<void>
    {
        const url = `${this.url}/${photoId}`;

        return this.http
            .delete<void>(url)
            .toPromise();
    }

    update(photo: UpdatePhotoModel): Promise<void>
    {
        return this.http
            .put<void>(this.url, photo)
            .toPromise();
    }

}
